import SmoothScroll from 'smooth-scroll';

// スムーススクロール
// const scroll = new SmoothScroll('a[href*="#"]', {
//     speed: 300,
//     offset: function (anchor, navigation) {
//         if (screen.width <= 640) {
//             return 55;
//         } else {
//             return 149;
//         }
//     },
//     easing: 'easeInOutQuint',
//     updateURL: false,
//     popstate: false
// });

const trigger = document.querySelector("#trigger");
const navigation = document.querySelector("#navigation");
trigger.addEventListener("click", (event) => {
    
    event.currentTarget.classList.toggle("active")
    navigation.classList.toggle("active")
    
})

const navigationLink = document.querySelectorAll("#navigation a");

navigationLink.forEach(item => {
    item.addEventListener("click", (event) => {
        trigger.classList.toggle("active")
        navigation.classList.toggle("active")
    })
});
